<template>
  <div class="agency">
    <div class="flex flex-col">
      <div class="font-bold">
        {{ $t("agency") }}
      </div>
      <transition name="fade" mode="out-in">
        <div
          v-if="agency && !showModify"
          key="agencyInfo"
          :style="{ color: colors.colorPrimary }"
          class="agency__text"
        >
          {{ agency.name }}&nbsp;({{
            BillingUtil.formatBillingValue(
              agency.discount,
              getTypeSymbol(agency.discountType)
            )
          }}&nbsp;{{ $t("common.discount") }}
          <span v-if="agency.bonus !== null"
            >,&nbsp;{{ agency.bonus
            }}{{ getTypeSymbol(agency.bonusType) }}&nbsp;{{
              $t("bonus")
            }} </span
          >)<v-btn
            class="border-2 rounded-config30 w-full sm:w-36 mb-2 sm:mb-0 mx-1"
            outlined
            color="warning"
            @click="modifyAgency"
          >
            {{ $t("common.modify") }} </v-btn
          ><v-btn
            class="border-2 rounded-config30 w-full sm:w-36 mb-2 sm:mb-0 mx-1"
            outlined
            color="error"
            @click="deleteAgency"
          >
            {{ $t("common.delete") }}
          </v-btn>
        </div>
        <div
          class="flex flex-col sm:flex-row md:w-1/3 w-full mt-1"
          v-else
          key="enterAgency"
        >
          <v-text-field
            class="lg:w-1/6 w-full mr-2"
            v-model="agencyCode"
            dense
            :label="$t('agencyCode')"
            :outlined="true"
            :backgroundColor="colors.colorSurfaceMedium + '33'"
            :dark="colors.colorBackground === '#000000' ? true : false"
            :error-messages="agencyCodeErrors"
            @keyup.enter="validateAgencyCode"
          >
          </v-text-field>
          <v-btn
            class="border-2 rounded-config30 w-full sm:w-36 mb-2 sm:mb-0"
            outlined
            :color="colors.colorPrimary"
            @click="validateAgencyCode"
          >
            {{ $t("mobile.damages.validate") }}
          </v-btn>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { maxLength } from "vuelidate/lib/validators";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";

export default {
  name: "AgencyReserve",
  props: { currency: { type: String, required: true } },
  validations: {
    agencyCode: { maxLength: maxLength(10) },
  },
  data() {
    return {
      BillingUtil,
      agencyCode: "",
      agency: null,
      showModify: false,
      error: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      colors: "configuration/getColors",
    }),
    agencyCodeErrors() {
      const errors = [];
      if (this.$v.agencyCode.$dirty) {
        this.error && errors.push(this.$t("agencyCodeNotFound"));
        !this.$v.agencyCode.maxLength &&
          errors.push(this.$t(this.$CONSTANTS.ERROR.MAX_LENGTH, { count: 10 }));
      }
      return errors;
    },
  },
  methods: {
    modifyAgency() {
      this.showModify = true;
      this.agency = null;
      this.$emit("input", null);
    },
    deleteAgency() {
      this.agencyCode = "";
      this.$v.$reset();
      this.agency = null;
      this.showModify = true;
      this.$emit("input", null);
    },
    validateAgencyCode() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.user.status !== "loading") {
        this.$store
          .dispatch("user/getAgencyByCodeFrame", {
            code: this.agencyCode,
          })
          .then((resp) => {
            if (resp.status === 200) {
              this.agency = Object.assign({}, resp.data);
              this.showModify = false;
              this.$emit("input", this.agency);
              this.error = false;
            } else {
              this.agency = null;
              this.$emit("input", null);
              this.error = true;
            }
          })
          .catch(() => (this.error = true))
          .finally(this.toggleLoading);
      }
    },
    getTypeSymbol(symbol) {
      return symbol === this.$CONSTANTS.SYMBOL.PERCENTAGE
        ? symbol
        : this.currency;
    },
  },
};
</script>

<style lang="scss" scoped>
.agency {
  min-height: 94px;

  &__text {
    word-break: break-word;
    white-space: pre-line;
  }
}
</style>
